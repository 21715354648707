<template>
    <button :disabled="loading || disabled" :class="`y-${type}-btn y-${size}-size`" @click.prevent="onClick">
        <template v-if="!loading">
            <slot>

            </slot>
        </template>
        <img :src="require('@/assets/loadingDot.svg')" alt style="width: 50px;" v-else /> 
    </button>
</template>

<script>
export default {
    props:{
        loading: {
            default: false
        },
        disabled: {
            default: false
        },
        type: {
            default: 'blue'
        },
        size: {
            default: 'md'
        },
    },
    methods:{
        onClick(e){
            this.$emit('click', e)
        }
    }
}
</script>