<template>
    <div class="y-inputField">
        <div class="y-labelText" v-if="label">
            {{label}}
        </div>
        <div :class="inputClass()">
            <slot name="leftIcon"></slot>
            <input 
                :type="type"  
                :value="value" 
                @input="onChange($event)" 
                :name="label" 
                :class="error ? 'y-red-outline' : ''" 
                :placeholder="placeholder" 
                @change="change"
                @focus="onFocus"
                @blur="onBlur"
                @keypress="onKeypress"
                @keydown="onKeydown"
                @keyup="onKeyup"
                @keyup.enter="onKeyupEnter"
                @keypress.enter="onKeypressEnter"
                @keyup.tab="onKeyupTab"
                @keydown.tab="onKeydownTab"
                :readonly="readonly"
                >
            <slot name="rightIcon"></slot>
        </div>
        <slot name="topErrorText"></slot>
        <div class="y-errorText y-red-text" :style="error ? 'opacity: 1 !important;': ''" v-if="withError">
            {{errorMsg}}
        </div>
        <slot name="bottomErrorText"></slot>

    </div>
</template>
<script>
export default {
  emits: ['onFocus','input',
    'blur','keypress',
    'keyup','keydown',
    'keypress.enter','keyup.enter','change',
    'keydown.tab','keyup.tab'],
  props: {
    size: {
      default: 'lr'
    },
    value: {
      required: true
    },
    label: {
      default: null
    },
    error: {
      default: false
    },
    errorMsg: {
      default: 'Tidak Boleh Kosong'
    },
    rightIcon: {
      default: false
    },
    leftIcon: {
      default: false
    },
    type: {
      default: 'text'
    },
    withError: {
      default: true
    },
    placeholder: {
      default: null
    },
    readonly: {
      default: false
    },
  },
  methods: {
    inputClass(){
      const size = `y-${this.size}-size`
      if(this.rightIcon) return `y-hasIcon-right ${size}`
      else if(this.leftIcon) return `y-hasIcon-left ${size}`
      else return size
    },
    onFocus(e){
      this.$emit('onFocus',e)
    },
    onChange(e){
      const value = e.target.value
      this.$emit('input',value)
    },
    change(e){
      this.$emit('change',e)
    },
    onBlur(e){
      this.$emit('blur',e)
    },
    onKeypress(e){
      this.$emit('keypress',e)
    },
    onKeydown(e){
      this.$emit('keydown',e)
    },
    onKeyup(e){
      this.$emit('keyup',e)
    },
    onKeyupEnter(e){
      this.$emit('keyup.enter',e)
    },
    onKeypressEnter(e){
      this.$emit('keypress.enter',e)
    },
    onKeyupTab(e){
      this.$emit('keyup.tab',e)
    },
    onKeydownTab(e){
      this.$emit('keydown.tab',e)
    },
  }
}
</script>