import { mapActions } from 'vuex'
import ButtonCustom from '@/components/Helper/Button';
import InputCustom from '@/components/Helper/Input';
import {
    convertDateTime, timeFormat
} from '@/common/timeformat'

export default {
    data: () => ({
        logo_url: 'https://api.' + window.location.hostname.substring(6) + '/file/logo_bank/',
        slider_url: 'https://api.' + window.location.hostname.substring(6) + '/file/slider/',
        promo_url: 'https://api.' + window.location.hostname.substring(6) + '/file/promo/',
    }),
    methods: {
        ...mapActions({
            setActiveTab: 'setActiveTab',
            setBreadCrumb: 'setBreadCrumb',
            showSnackbar: 'showSnackbar',
        }),
        getResponse(module){
            const status = this.$store.getters[`${module}/getStatus`]
            const msg = this.$store.getters[`${module}/getMsg`]
            return {status, msg}
        },
        convertDateTime(value){
            if(!value) return '-'
            return convertDateTime(value)
        },
        sessionFormat(session){
            if(!session) return '-'
            return `${session.name} (${timeFormat(session.beginTime)} - ${timeFormat(session.endTime)})`
        },
    },
    components: {
        ButtonCustom,
        InputCustom
    }
}